import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginButton from "./components/Buttons/Button";
import ListaProdutosMercadoLivre from "./ListaProdutosMercadoLivre";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LoginButton />} />
        </Routes>
        <Routes>
          <Route
            path="/listaProdutos"
            element={<ListaProdutosMercadoLivre />}
          />
          {/* Você pode adicionar mais rotas aqui */}
        </Routes>
      </div>
    </Router>
  );
};

export default AppRoutes;
