import React, { useEffect, useState } from "react";
import { API } from "../../services/api";
import ListaProdutosMercadoLivre from "../../ListaProdutosMercadoLivre";
import Swal from "sweetalert2";
const LoginButton: React.FC = () => {
  const handleLogin = async () => {
    try {
      const result = await API.post("/login");
      window.location.href = result.data.linkRedirect;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
    }
  };

  async function executeReplace() {
    const url = new URL(window.location.href);
    const code: any = url.searchParams.get("code");
    const lastPart = code?.split("-").pop();
    if (code) {
      localStorage.setItem("CODE", code?.toString());
      try {
        let result;

        const resultLogin = await API.post("/listarLogin", {
          userid: lastPart,
        });

        if (resultLogin.data.data.length > 0) {
          result = await API.post("/getCode", {
            token_refresh: resultLogin?.data?.data[0].refresh_token,
          });
        } else {
          result = await API.post("/getCode", {
            code: code,
          });
        }

        localStorage.setItem("USER_ID", resultLogin.data.data[0].user_id);
        localStorage.setItem(
          "ACCESS_TOKEN",
          resultLogin.data.data[0].access_token
        );
        localStorage.setItem(
          "REFRESH_TOKEN",
          resultLogin?.data?.data[0].refresh_token
        );

        window.location.href = "/listaProdutos";
      } catch (e) {}
    }
  }

  async function contaCompartilhada() {
    const { value: numeroConta } = await Swal.fire({
      title: "Acessar Conta Compartilhada",
      input: "text",
      inputLabel: "Número da Conta",
      inputPlaceholder: "Digite aqui...",
      showCancelButton: true,
      confirmButtonText: "Acessar",
      cancelButtonText: "Cancelar",
      inputValidator: (value) => {
        if (!value) {
          return "Você precisa inserir um valor!";
        }
      },
    });

    if (numeroConta) {
      const resultLogin = await API.post("/listarLogin", {
        userid: numeroConta,
      });

      if (resultLogin.data.data.length > 0) {
        const result = await API.post("/getCode", {
          token_refresh: resultLogin?.data?.data[0].refresh_token,
        });

        localStorage.setItem("USER_ID", resultLogin.data.data[0].user_id);
        localStorage.setItem(
          "ACCESS_TOKEN",
          resultLogin.data.data[0].access_token
        );
        localStorage.setItem(
          "REFRESH_TOKEN",
          resultLogin?.data?.data[0].refresh_token
        );
        window.location.href = "/listaProdutos";
      } else {
        await Swal.fire({
          title: "Opss...",
          text: "Não foi encontrado conta com número " + numeroConta + "!",
          icon: "error",
          showCloseButton: true,
          confirmButtonText: "OK",
        });
      }
    }
  }

  useEffect(() => {
    executeReplace();
  }, []);

  return (
    <div>
      <button
        onClick={handleLogin}
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
      >
        Entrar
      </button>
      <button
        onClick={contaCompartilhada}
        className="mx-2 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
      >
        Conta Compartilhada
      </button>
    </div>
  );
};

export default LoginButton;
