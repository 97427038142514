import { useState } from "react";
import Swal from "sweetalert2";
import { API } from "../services/api";

export function useListaProdutosMercadoLivre() {
  const [rotina, setRotina] = useState<"insert" | "edit">("insert");
  const [produtos, setProdutos] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [currentDateTime, setCurrentDateTime] = useState<string>(
    new Date().toLocaleString()
  );

  const handleCheckboxChange = (mlbId: string) => {
    setSelectedIds((prevSelected) => {
      if (prevSelected.includes(mlbId)) {
        return prevSelected.filter((id) => id !== mlbId);
      } else {
        return [...prevSelected, mlbId];
      }
    });
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const allIds = produtos.map((produto: any) => produto.produto_mlbid);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const isAllSelected =
    produtos?.length > 0 && selectedIds?.length === produtos?.length;

  async function getProdutos() {
    const produtos: any = await API.post("/listarProdutos", {
      userid: localStorage.getItem("USER_ID"),
    });
    setProdutos(produtos.data.data);
  }

  async function checkAllTime() {
    for (var i in produtos) {
      await API.post("/alterarPrecoFinal", {
        code: localStorage.getItem("CODE"),
        mlbId: produtos[i]?.produto_mlbid?.toLocaleUpperCase(),
        minimumValue: Number(produtos[i].produto_valorminimo),
        access_token: localStorage.getItem("ACCESS_TOKEN"),
      });

      const searchProduct: any = await API.post("/buscarProduto", {
        mlbId: produtos[i]?.produto_mlbid?.toLocaleUpperCase(),
      });

      console.log("searchProduct", searchProduct);
      if (searchProduct.data.result) {
        let data = searchProduct.data.data;
        console.log("data", data);
        try {
          const response: any = await API.put("/editarProduto", {
            produto_mlbid: data.id,
            produto_vendedor: data.seller_id,
            produto_titulo: data.title,
            produto_preco: data.price,
            produto_valorminimo: produtos[i].produto_valorminimo,
          });
          if (response.data.result) {
            await getProdutos();
            Swal.fire("Produto Atualizado com Sucesso!", "", "success");
          } else {
            Swal.fire("Erro", response.data.message, "error");
          }
        } catch (error: any) {
          console.log("error", error);
          // Swal.fire("Produto não atualizado.", error.response.data, "info");
        }
      }
    }

    await getProdutos();
  }

  const handleAddProduct = async (inputMlbId: string, valor_minimo: string) => {
    if (inputMlbId) {
      const mlbId = /^\d+$/.test(inputMlbId) ? `MLB${inputMlbId}` : inputMlbId;

      try {
        const searchProduct: any = await API.post("/buscarProduto", {
          mlbId: mlbId,
        });

        if (searchProduct.data.result) {
          let data = searchProduct.data.data;

          const { value: confirmAdd } = await Swal.fire({
            title: "Confirmação",
            text: `Deseja adicionar o produto '${data.title}' na atualização de preços automática?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          });

          if (confirmAdd) {
            try {
              const response: any = await API.post("/inserirProdutoBD", {
                produto_mlbid: data.id,
                produto_vendedor: data.seller_id,
                produto_titulo: data.title,
                produto_preco: data.price,
                produto_valorminimo: valor_minimo,
              });

              if (response.data.result) {
                Swal.fire("Produto Adicionado com Sucesso!", "", "success");
              } else {
                Swal.fire("Erro", response.data.message, "error");
              }
            } catch (error) {
              Swal.fire("Produto não adicionado.", "", "info");
            }
          }
        } else {
          Swal.fire({
            title: "Opss...",
            text:
              searchProduct.data.data.error === "not_found"
                ? "Não foi encontrado item com id " + mlbId
                : searchProduct.data.data.message,
            icon: "error",
            showCloseButton: true,
            confirmButtonText: "OK",
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  const ModalAddProduct = async () => {
    await Swal.fire({
      title: "Cadastrar Produto",
      html: `
            <input type="text" id="mlbId" class="swal2-input" placeholder="Digite o MLB ID do seu produto">
            <input type="text" id="valor_minimo" class="swal2-input" placeholder="Digite o Valor Mínimo do seu produto">
          `,
      focusConfirm: false,
      preConfirm: () => {
        const mlbId = (document.getElementById("mlbId") as HTMLInputElement)
          .value;
        const valor_minimo = (
          document.getElementById("valor_minimo") as HTMLInputElement
        ).value;

        if (!mlbId || !valor_minimo) {
          Swal.showValidationMessage("Por favor, preencha todos os campos");
        }
        return { mlbId, valor_minimo };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleAddProduct(result.value.mlbId, result.value.valor_minimo);
      }
    });
  };

  async function handleEdit(data: any) {
    const resultLista = await API.post("/listarProdutos", {
      mlbid: data.produto_mlbid,
      userid: data.produto_vendedor,
    });

    console.log("resultLista", resultLista);

    await Swal.fire({
      title: "Editar Produto",
      html: `
              <input type="text" id="mlbId" value=${resultLista.data.data[0].produto_mlbid} disabled class="swal2-input" placeholder="Digite o MLB ID do seu produto">
              <input type="text" id="valor_minimo" value=${resultLista.data.data[0].produto_valorminimo} class="swal2-input" placeholder="Digite o Valor Mínimo do seu produto">
            `,
      focusConfirm: false,
      preConfirm: () => {
        const mlbId = (document.getElementById("mlbId") as HTMLInputElement)
          .value;
        const valor_minimo = (
          document.getElementById("valor_minimo") as HTMLInputElement
        ).value;

        if (!mlbId || !valor_minimo) {
          Swal.showValidationMessage("Por favor, preencha todos os campos");
        }
        return { mlbId, valor_minimo };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(result.value);
        const teste = await API.put("/editarProduto", {
          ...resultLista.data.data[0],
          produto_mlbid: result.value.mlbId,
          produto_valorminimo: result.value.valor_minimo,
        });
        await getProdutos();
      }
    });
  }

  return {
    isAllSelected,
    produtos,
    selectedIds,
    currentDateTime,
    rotina,
    setRotina,
    handleCheckboxChange,
    handleSelectAllChange,
    getProdutos,
    checkAllTime,
    ModalAddProduct,
    handleEdit,
  };
}
